import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import tw from "twin.macro";

import { mapEdgesToNodes } from "../../lib/helpers";
import Slider from "../Slider/SliderStandard";

import Quote from "../../images/0.0 Repeating Modules/Testimonials/quote.svg";

const StyledSlider = styled.div`
  .slick-arrow {
    ${tw`w-auto top-0 transform-none`}
  }
  .slick-prev {
    ${tw`left-0`}
  }
  .slick-next {
    ${tw`right-0`}
  }
  .slick-dots {
    ${tw`relative justify-center items-center space-x-2.5 !flex`}
    li {
      ${tw`h-2 w-2 !mr-0 bg-primary-50/40 rounded-full hover:bg-primary-50 transition-colors duration-300 ease-linear`}
      &.slick-active {
        ${tw`bg-primary-50`}
      }
    }
  }
`;

const Testimonial = ({ headingLevel, className }) => {
  const data = useStaticQuery(graphql`
    {
      yelp: file(relativePath: { eq: "reviews/Yelp-alt.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 61)
        }
      }
      google: file(relativePath: { eq: "reviews/Google.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 76)
        }
      }
      facebook: file(relativePath: { eq: "reviews/Facebook.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 99)
        }
      }
      testimonials: allSanityTestimonials(
        sort: { fields: [order], order: ASC }
      ) {
        edges {
          node {
            id
            review
            name
            profilePicture {
              asset {
                gatsbyImageData(layout: CONSTRAINED, width: 56)
              }
            }
            businessNameTitle
            featured
            platform
          }
        }
      }
    }
  `);
  const testimonialsNodes = (data || {}).testimonials
    ? mapEdgesToNodes(data.testimonials).filter((items) => items.featured)
    : [];

  const HeadingTag = headingLevel || "h2";

  return (
    <div className="container">
      <section
        className={`mb-20 rounded-3xl bg-primary-900 py-16 px-4 md:mb-32 md:px-16 md:py-22 ${className}`}
      >
        <img src={Quote} alt="Quotation mark" className="mx-auto mb-12" />
        <header className="mb-9 text-center md:mb-8">
          <HeadingTag className="text-white">
            Here’s Why We Do What We Do
          </HeadingTag>
        </header>

        <div>
          <StyledSlider className="relative">
            <Slider>
              {testimonialsNodes.slice(0, 3).map((testimonial, i) => {
                return (
                  <div key={i}>
                    <blockquote className="mx-auto text-center md:max-w-[720px]">
                      <q className="mb-9 text-white/95 before:hidden">
                        {testimonial.review}
                      </q>

                      <footer className="mb-2">
                        <div>
                          {testimonial.profilePicture &&
                            testimonial.profilePicture.asset && (
                              <div className="mb-2 inline-flex justify-center rounded-full border border-white">
                                <GatsbyImage
                                  image={
                                    testimonial.profilePicture.asset
                                      .gatsbyImageData
                                  }
                                  loading="lazy"
                                  className="z-0 rounded-full"
                                />
                              </div>
                            )}
                          <cite className="not-italic">
                            <div className="mb-0 font-body text-xs font-bold text-primary-50">
                              {testimonial.name}
                            </div>
                            {testimonial.businessNameTitle && (
                              <div className="font-body text-sm font-bold uppercase tracking-wide text-primary-500">
                                {testimonial.businessNameTitle}
                              </div>
                            )}
                          </cite>
                        </div>
                      </footer>

                      <div className="mb-10 flex justify-center">
                        {testimonial.platform === "Google" && (
                          <GatsbyImage
                            image={data.google.childImageSharp.gatsbyImageData}
                            loading="lazy"
                          />
                        )}
                        {testimonial.platform === "Yelp" && (
                          <GatsbyImage
                            image={data.yelp.childImageSharp.gatsbyImageData}
                            loading="lazy"
                          />
                        )}
                        {testimonial.platform === "Facebook" && (
                          <GatsbyImage
                            image={
                              data.facebook.childImageSharp.gatsbyImageData
                            }
                            loading="lazy"
                          />
                        )}
                        {/* {testimonial.platform === "LinkedIn" && (
                          <GatsbyImage
                            image={
                              data.linkedinAlt.childImageSharp.gatsbyImageData
                            }
                            loading="lazy"
                          />
                        )} */}
                      </div>
                    </blockquote>
                  </div>
                );
              })}
            </Slider>
          </StyledSlider>
        </div>
      </section>
    </div>
  );
};

export default Testimonial;
